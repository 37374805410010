export const hero = {
    title: `Point-of-sale systems in New York`,
    paragraphs: [
        `With five boroughs, over 8 million locals, and hundreds of thousands of tourists visiting at any given time, New York is more populous, diverse, and transient than any other city in America. That’s why it's one of the best, yet toughest, cities to start and grow a business.`,
        `Building a business in The City isn’t a one-person job. That’s why many local businesses are turning to SpotOn as a technology partner that understands business owners and is invested in helping you succeed. SpotOn’s business software and point-of-sale (POS) system in New York can help you reach and sell to more customers, streamline administrative work, and organize and track customer buying habits while providing the best customer experience in the industry.`,
      ],
  };

  export const textWithVideoAndImage = {
    heading: "Real users rate SpotOn as the #1 POS system",
    paragraph: "According to real user reviews submitted to Capterra, SpotOn is the top-rated point-of-sale for Restaurants, Bars, Retail, and Small Business. SpotOn received the highest marks among its competition across all categories measured, including functionality, ease of use, customer support, and value for money.",
    thumbnailImageName: "new-york-video-thumb.png",
    videoId: "aeLjiqXkwJU",
    imageName: "new-york-image-1.png",
  };
  
  export const imageWithText = {
    heading: "POS systems to fit your business",
    paragraphs: [
      `From Gotham Restaurant to Yankee Stadium, businesses across the city use SpotOn’s point-of-sale solutions in New York. The capabilities of our system and software improve business efficiency and allow employees to focus less on administrative tasks and more on delivering an outstanding customer experience.`,
      `SpotOn’s POS for restaurants, bars, or other retail establishments is designed with your business in mind. Our goal is to provide a system that fits your unique needs. That’s why SpotOn accepts all types of payments (in-store and online), enables tracking customer preferences and sales goals, produces detailed purchase reports, and helps you to communicate deals and promotions with customers.`,
      `Having one integrated solution that handles all those tasks is a life-saver. So whether you’re in the restaurant industry or running a retail boutique, SpotOn and our knowledgeable team have the solutions and support you need to remain competitive.`,
    ],
    imageName: "new-york-image-2.png",
  };
  
  export const pros = {
    heading: `POS solutions in New York for restaurants, bars, retail, and enterprise businesses`,
    paragraphs: [
      `Whether you’re in a furniture store on Staten Island, running a restaurant in the West Village, or have a club in Brooklyn, each business calls for a different set of software and tools. You need a POS system capable of handling your specific needs while adjusting to your location and unique clientele.`,
      `One of the biggest benefits of SpotOn’s POS software in New York is that it offers flexible payment options while also providing superior customer service. The customizable tech allows you to control the number of orders you get and accept payment from anywhere.`,
      `If you are running one of the many popular New York City food trucks or selling your products at a market in Central Park, mobility and customization are essential to your business’s success. SpotOn’s POS system can handle the various changes while still enhancing your customer experience.`,
      `Here is a list of some of the many industries that benefit from SpotOn’s flexible small business POS system in New York:`,
    ],
    bullets: [
      `Bars, nightclubs, distilleries, and breweries`,
      `Restaurants, from fine dining to quick service`,
      `Retail, including sporting goods, home decor, clothing, and apparel`,
      `Food trucks`,
      `Professional services such as accountants `,
      `Local services such as auto repair, plumbing and nail salons`,
      `Enterprise businesses such as educational campuses, sports and entertainment, music venues, and so much more`,
    ],
    imageName: "new-york-image-3.png",
  };
  
  export const mainFeature = {
    heading: `Regardless of your industry, we have the customized tech solutions you need to run your business with confidence. `,
    imageName: "san-diego-image-3.png",
  };

  export const quoteWithVideo = {
    quote: `"SpotOn makes my job much easier"`,
    person: "KwangHo Lee",
    location: "Owner of 3-location Japanese restaurant, Momoya",
    thumbnailImageName: "new-york-image-4.png",
    withImageOnly: true,
    url: "/case-studies/momoya/",
    ctaTitle: "Read The Story",
  };
  
  export const pros2 = {
    heading: `Looking for a POS for restaurants`,
    topText: "Manage and track all essential functions of your restaurant in real-time, including:",
    bottomText: `SpotOn’s <a href="/restaurant-pos" class="text-primary">restaurant POS</a> has been rated by real restaurant owners and operators as the #1 restaurant POS across all categories measured, including functionality, ease of use, customer support, and value for money.`,
    bullets: [
      `Payments, from credit cards to Apple Pay`,
      `Reservations and a digital waitlist`,
      `Secure payment processing (including mobile and contactless payment options)`,
      `Menu modifications on the fly`,
      `Item availability`,
      `Happy hour discounts and other deals`,
      `Guest loyalty programs and promotions`,
      `Employee scheduling, tip distribution and labor compliance`,
      `90+ fully-interactive reports available in the cloud`,
    ],
    imageName: "new-york-image-5.png",
  };
  
  export const footer = {
    heading: `If you’re looking for POS solutions in New York and want to learn more about what SpotOn’s POS system can do for your business, contact us today for a free demo`,
    title: `Do you run a bar, brewery or nightclub?`,
    paragraphs: [
      `SpotOn’s POS for bars is also the <a href="/restaurant-pos/capterra-competive-comparison/#best-bar-pos" class="text-primary">top-rated bar point-of-sale system,</a> according to real users.`,
      `If you’re looking for a true partner for post-of-sale solutions in New York, SpotOn’s team of local experts has you covered. <a href="/demo" class="text-primary">Contact us today for a free demo</a> of our POS solutions in New York. This demo will show you exactly how SpotOn can work for your business. Upon sign up, SpotOn is providing new New York-area restaurants (and other select cities) with a free photoshoot through Snappr, a nationwide network of professional photographers. <a href="https://spotonteam.atlassian.net/wiki/spaces/SPO/pages/2118483969/Snappr+Custom+Photography+-+Pilot" class="text-primary">Find out more information on the Snappr & SpotOn partnership here.</a>`,
    ],
    cta: {
      target: "#form",
      title: "Learn more",
    },
  };
  
  export const terms = {
    title: "Snappr terms & conditions.",
    paragraphs: [
      `The Snappr promotion offered through SpotOn is only available to those customers purchasing for their own personal or their company's use in the states of Georgia, New York, and California, from 7/1/2022 to 12/31/22. These images produced during the promotion are intended for personal or company promotional use and not intended for any sort of resale. All promotions are non-transferable. Promotional offers end at 11:59 PM on the expiration date unless indicated otherwise and are available only to U.S. residents, exclusively provided through the Snappr photography company.`,
      `Any discounts, prizes, or promotional considerations applicable to this order apply only to the initial purchase and purchase price. They are not applicable to other charges, including: previous purchases, current or existing monthly fees, unpaid balances, or any future charges related to the product(s).`,
    ],
  };
  